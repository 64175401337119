import * as THREE from 'three'
import bg from './bg1.png'
import earth from './earth1.jpg'

function isMobileDevice() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  // Check if the user agent contains common keywords found in mobile devices
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
}


particlesJS("particles-js", {
  "particles": {
    "number": {
      "value": 80,
      "density": {
        "enable": true,
        "value_area": 800
      }
    },
    "color": {
      "value": "#496B7A"
    },
    "shape": {
      "type": "circle",
      "stroke": {
        "width": 0,
        "color": "#000000"
      },
      "polygon": {
        "nb_sides": 5
      },
      "image": {
        "src": "img/github.svg",
        "width": 100,
        "height": 100
      }
    },
    "opacity": {
      "value": 0.5,
      "random": false,
      "anim": {
        "enable": false,
        "speed": 1,
        "opacity_min": 0.1,
        "sync": false
      }
    },
    "size": {
      "value": isMobileDevice() ? 0.7 : 1.8,
      "random": true,
      "anim": {
        "enable": false,
        "speed": 40,
        "size_min": 0.1,
        "sync": false
      }
    },
    "line_linked": {
      "enable": true,
      "distance": 150,
      "color": "#496B7A",
      "opacity": 1,
      "width": isMobileDevice() ? 0.3 : 0.5
    },
    "move": {
      "enable": true,
      "speed": 6,
      "direction": "none",
      "random": false,
      "straight": false,
      "out_mode": "out",
      "bounce": false,
      "attract": {
        "enable": false,
        "rotateX": 600,
        "rotateY": 1200
      }
    }
  },
  "interactivity": {
    "detect_on": "canvas",
    "events": {
      "onhover": {
        "enable": false,
        "mode": "repulse"
      },
      "onclick": {
        "enable": false,
        "mode": "push"
      },
      "resize": true
    },
    "modes": {
      "grab": {
        "distance": 400,
        "line_linked": {
          "opacity": 1
        }
      },
      "bubble": {
        "distance": 400,
        "size": 40,
        "duration": 2,
        "opacity": 8,
        "speed": 3
      },
      "repulse": {
        "distance": 200,
        "duration": 0.4
      },
      "push": {
        "particles_nb": 4
      },
      "remove": {
        "particles_nb": 2
      }
    }
  },
  "retina_detect": true
});

export default class Template {
    constructor(selector) {

        // getting the heights of the containing windows
        this.container = selector;
        this.width = this.container.offsetWidth;
        this.height = this.container.offsetHeight;

        this.scene = new THREE.Scene();
        this.scene.background = new THREE.TextureLoader().load(bg);

        this.renderer = new THREE.WebGLRenderer({
            antialias: true
        });
        this.renderer.setPixelRatio(window.devicePixelRatio);
        this.renderer.setSize(this.width, this.height);
        this.renderer.physicallyCorrectLights = true;
        this.renderer.outputColorSpace = THREE.SRGBColorSpace;

        this.container.appendChild(this.renderer.domElement);
        
        this.camera = new THREE.PerspectiveCamera(
          35, this.width / this.height,
          0.01,
          1000
        );
        this.camera.position.set(0, 0, 7);


        this.texture = window.pJSDom.pJS.canvas.tex || new THREE.CanvasTexture();
        this.points = [];
        this.variable = (Math.random() - 0.5) * 0.05;
        this.objs = new THREE.Group();
        
        
        this.setupResize();
        this.addObjects();
        this.addAround();
        this.resize();
        this.render();
    }

    setupResize() {
        window.addEventListener('resize', this.resize.bind(this));
    }

    resize() {
        // we recalculate the sizes because the window has been resized
        this.width = this.container.offsetWidth;
        this.height = this.container.offsetHeight;

        // we reset the size of the renderer to the correct size of the screen
        this.renderer.setSize(this.width, this.height);
        
        // we also recalculate the aspect ratio of the screen when the screen is resized
        this.camera.aspect = this.width / this.height;

        // we then update the projection matrix of the camera
        // so that the camera can recalculate how it renders the scene
        this.camera.updateProjectionMatrix();
    }

    addObjects() {

        const earthTexture = new THREE.TextureLoader().load(earth);
        
        this.geometry = new THREE.SphereGeometry(isMobileDevice() ? 1.9 : 1.8, 50, 50);
        this.geometry2 = new THREE.SphereGeometry(1.82, 50, 50);
        this.partGeometry = new THREE.SphereGeometry(2, 30, 30);
        this.partMaterial = new THREE.MeshBasicMaterial({
            transparent: true,
            map: this.texture,
        })

        this.material = new THREE.ShaderMaterial({
            transparent: true,
            uniforms: {
              fresnelPower: { value: 1 },
              fresnelColor: { value: new THREE.Color('#fff') },
              insideColor: { value: new THREE.Color('#1d406b') },
              earthTexture: { value: earthTexture },
            },
            vertexShader: `
              uniform float fresnelPower;
              varying float frc;
              varying vec2 vuv;

              void main() {
                vuv = uv;
                vec4 mvPosition = modelViewMatrix * vec4(position, 1.0);
                vec4 p = projectionMatrix * mvPosition;
                gl_Position = p;
                vec3 normal = normalize(normalMatrix * normal);
                vec3 eyeDir = normalize(mvPosition.xyz);
                frc = pow(1.0 - dot(normal, eyeDir), fresnelPower);
              }
            `,
            fragmentShader: `
              uniform vec3 fresnelColor;
              uniform vec3 insideColor;
              uniform sampler2D earthTexture;
              varying float frc;
              varying vec2 vuv;

              void main() {
                vec4 texColor = texture2D(earthTexture, vuv);
                gl_FragColor = mix(vec4(insideColor * 1.4, 1.0), clamp(texColor * vec4(fresnelColor, 1.0), 0.1, 1.0), frc) + texColor;
                gl_FragColor.a = 0.4;
              }
            `,
        });
        
        this.material2 = new THREE.ShaderMaterial({
            transparent: true,
            side: THREE.BackSide,
            uniforms: {
              fresnelPower: { value: 1 },
              fresnelColor: { value: new THREE.Color('#fff') },
              insideColor: { value: new THREE.Color('#1d406b') },
              earthTexture: { value: earthTexture },
            },
            vertexShader: `
              uniform float fresnelPower;
              varying vec3 vPosition;
              varying float frc;
              varying vec2 vuv;

              void main() {
                vuv = uv;
                vPosition = position;
                vec4 mvPosition = modelViewMatrix * vec4(position, 1.0);
                vec4 p = projectionMatrix * mvPosition;
                gl_Position = p;
                vec3 normal = normalize(normalMatrix * normal);
                vec3 eyeDir = normalize(mvPosition.xyz);
                frc = pow(1.0 - dot(normal, eyeDir), fresnelPower);
              }
            `,
            fragmentShader: `
              uniform vec3 fresnelColor;
              uniform vec3 insideColor;
              uniform sampler2D earthTexture;
              varying vec3 vPosition;
              varying float frc;
              varying vec2 vuv;

              void main() {
                float radial = 1.- vPosition.z;
                vec4 texColor = texture2D(earthTexture, vuv);
                gl_FragColor = mix(vec4(insideColor * 3.4, 1.0), clamp(texColor * vec4(fresnelColor, 1.0), 0.1, 1.0), frc) + texColor;
                gl_FragColor.a = 0.4;
              }
            `,
        });
        const sphereGeometry = new THREE.SphereGeometry(2.5, 10, 10);
        const pointMaterial = new THREE.PointsMaterial({
          size: 0.02,
        });

        const customVertexShader = `
            void main() {
                gl_PointSize = 5.0; // Adjust the size of the points
                gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
            }
        `;

        const customFragmentShader = `
            uniform vec3 color;

            void main() {
                // Calculate the distance from the center of the point
                float dist = distance(gl_PointCoord, vec2(0.5, 0.5));
                
                // Check if the point is outside the circle
                if (dist > 0.5) {
                    discard; // Discard pixels outside the circle
                }
                
                gl_FragColor = vec4(color, 1.0); // Set the color of the points
            }
        `;

        // Create a shader material
        const customMaterial = new THREE.ShaderMaterial({
            uniforms:{color: {value: new THREE.Color('#496B7A')}},
            vertexShader: customVertexShader,
            fragmentShader: customFragmentShader,
        });
        
        const spherePoints = new THREE.Points(sphereGeometry, customMaterial);

        this.points = [spherePoints, spherePoints.clone(), spherePoints.clone(), spherePoints.clone(), spherePoints.clone()];
        this.points.forEach(p=>{
          p.scale.multiplyScalar((Math.random() + 1.4)*0.5);
          this.objs.add(p);
        })


        this.plane = new THREE.Mesh(this.geometry, this.material);
        this.objs.add(this.plane);
        this.plan2 = new THREE.Mesh(this.geometry2, this.material2);
        this.objs.add(this.plan2);

        this.plane2 = new THREE.Mesh(this.partGeometry, this.partMaterial);
        this.objs.add(this.plane2);
        
        this.plane3 = this.plane2.clone()
        this.plane3.scale.multiplyScalar(1.01);
        this.objs.add(this.plane3);

        this.objs.position.x = isMobileDevice() ? 1.9 : 3.3;

        this.scene.add(this.objs);
    }

    addAround(){
      this.aroundGeometry = new THREE.SphereGeometry(2, 30, 30);

      this.aroundMaterial = new THREE.ShaderMaterial({
          transparent: true,
          side: THREE.BackSide,
          uniforms: {
            fresnelPower: { value: 1 },
            fresnelColor: { value: new THREE.Color('#fff') },
            insideColor: { value: new THREE.Color('#1d406b') },
            // earthTexture: { value: earthTexture },
          },
          vertexShader: `
            varying vec2 vuv;
            varying vec3 vPosition;

            void main() {
              vuv = uv;
              vPosition = position;
              vec4 mvPosition = modelViewMatrix * vec4(position, 1.0);
              vec4 p = projectionMatrix * mvPosition;
              gl_Position = p;
            }
          `,
          fragmentShader: `
            varying vec2 vuv;
            varying vec3 vPosition;

            vec3 brightnessToColor(float b){
              b *= 0.5;
              return (vec3(b, b*b, b*b*b*b)/0.25)*.8;
            }

            void main() {
              float radial = 1. - vPosition.z;
              float brightness = 1. + radial * 0.83;
              radial *= radial*radial;
              gl_FragColor.rgb = brightnessToColor(brightness);
              gl_FragColor.a = radial;
            }
          `,
      });

      this.aroundMesh = new THREE.Mesh(this.aroundGeometry, this.aroundMaterial);
        // this.objs.add(this.aroundMesh);
    }

    render() {
        requestAnimationFrame(this.render.bind(this));
        this.plane.rotation.y += 0.005;
        this.plane2.rotation.y += 0.003;
        this.plane3.rotation.y -= 0.003;
        this.renderer.render(this.scene, this.camera);
        this.texture.needsUpdate = true;

        
        this.points[0].rotation.y += this.variable;
        this.points[0].rotation.x += this.variable;

        this.points[1].rotation.y += this.variable * 0.1;
        this.points[1].rotation.x += this.variable * -0.07;

        this.points[2].rotation.y += this.variable * 0.2;
        this.points[2].rotation.x += this.variable * 0.04;

        this.points[3].rotation.y += this.variable * 0.01;
        this.points[3].rotation.x += this.variable * 0.4;

        this.points[4].rotation.y += this.variable * 0.05;
        this.points[4].rotation.x += this.variable * 0.3;
    }
}

new Template(document.getElementById('container'));